<script setup lang="ts">
import list from './components/list.vue'
import detail from './components/detail.vue'

const pageType = ref<'list' | 'detail'>('list')
const instance = ref<any>()
</script>

<template>
  <page-container>
    <absolute-container style="padding: 0;" :need-bg="false">
      <list v-if="pageType === 'list'" v-model:page-type="pageType" v-model:instance="instance" />
      <detail v-else v-model:page-type="pageType" :instance="instance" />
    </absolute-container>
  </page-container>
</template>
