<script setup lang="ts">
import { QuestionCircleTwoTone, SyncOutlined } from '@ant-design/icons-vue'
import VueJsonPretty from 'vue-json-pretty'
import { keys } from 'lodash-es'
import { DriverRuntimeStateMap, DriverRuntimeStateTagColorMap } from '@/constant/driver'
import 'vue-json-pretty/lib/styles.css'

const props = defineProps(['instance'])
const pageType = defineModel('pageType')

function back() {
  pageType.value = 'list'
}

function getColumns() {
  if (props.instance.runtime.registeredMappings && props.instance.runtime.registeredMappings.length > 0) {
    const obj = props.instance.runtime.registeredMappings[0]
    const res = keys(obj).map((key) => {
      return { title: key, dataIndex: key }
    })

    return res
  }
  return []
}
</script>

<template>
  <content-header :title="instance?.driverInstance?.driverInstanceName ?? ''" :need-back="true" @on-back="back">
    <template #extra>
      <a-tag :color="DriverRuntimeStateTagColorMap.get(instance.runtime.state)">
        <template #icon>
          <SyncOutlined v-if="instance.runtime.state === 'AUTO_RESTARTING'" :spin="true" />
        </template>
        {{ DriverRuntimeStateMap.get(instance.runtime.state) }}
      </a-tag>
    </template>
  </content-header>
  <flex-content>
    <a-card title="驱动实例信息">
      <a-descriptions size="small" :column="2" bordered>
        <a-descriptions-item label="驱动名称" :span="2">
          {{ instance.driverInstance.driverName }}
        </a-descriptions-item>
        <a-descriptions-item label="采集周期">
          {{ instance.driverInstance.cycleSeconds }} 秒
        </a-descriptions-item>
        <a-descriptions-item label="自动启动">
          <a-tag :color="instance.driverInstance.autoStartEnabled ? 'green' : 'error'">
            {{ instance.driverInstance.autoStartEnabled ? '启用' : '禁用' }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="运行模式">
          {{ instance.driverInstance.standalone ? '独立' : '内嵌' }}
        </a-descriptions-item>
        <a-descriptions-item label="运行日志">
          {{ instance.driverInstance.runtimeLog }}
        </a-descriptions-item>
        <a-descriptions-item label="初始化参数" :span="2">
          <VueJsonPretty :data="instance.driverInstance.initParam" :item-height="20" :height="120" :virtual="true" :show-icon="true" :show-double-quotes="false" />
        </a-descriptions-item>
        <a-descriptions-item label="备注" :span="2">
          {{ instance.driverInstance.description }}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-card mt-10px title="运行时信息">
      <a-descriptions size="small" :column="2">
        <a-descriptions-item label="调度策略">
          {{ instance.runtime.schedulePolicy?.concurrentSupported ? '并行' : '串行' }}
        </a-descriptions-item>
        <a-descriptions-item label="帧间隔">
          {{ instance.runtime.schedulePolicy?.frameIntervalMillis ?? 0 }}毫秒
        </a-descriptions-item>
        <a-descriptions-item>
          <template #label>
            <a-space>
              任务数量
              <a-tooltip title="允许向线程池提交的最多任务数量">
                <QuestionCircleTwoTone :style="{ fontSize: '16px' }" />
              </a-tooltip>
            </a-space>
          </template>
          {{ instance.runtime?.maxPooledTasks ?? 0 }}
        </a-descriptions-item>
      </a-descriptions>

      <div mt-10px>
        <a-table :data-source="instance.runtime.registeredMappings" :columns="getColumns()" :pagination="false" bordered>
          <template #title>
            驱动实例提供的PaaS API
          </template>
        </a-table>
      </div>
    </a-card>
  </flex-content>
</template>
