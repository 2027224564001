export const DriverRuntimeStateMap = new Map<RuntimeState, string>([
  ['NEVER_STARTED', '从未启动过'],
  ['WORKING', '工作中'],
  ['AUTO_RESTARTING', '自动重启中'],
  ['STOPPED', '已停止'],
])

export const DriverRuntimeStateTagColorMap = new Map<RuntimeState, string>([
  ['NEVER_STARTED', 'warning'],
  ['WORKING', 'success'],
  ['AUTO_RESTARTING', 'processing'],
  ['STOPPED', 'default'],
])
