<script setup lang="ts">
import type { FormInstance, TableColumnType } from 'ant-design-vue'
import { cloneDeep, isBoolean, map, omit } from 'lodash-es'
import { getDriverDetailApi, getDriverListApi, updateDriverInstanceApi } from '@/api/driver'
import { InitDataOfDataTypeMap } from '@/constant/thing-model'

const props = defineProps(['instance'])
const emit = defineEmits(['confirm'])
const message = useMessage()
const open = defineModel<boolean>('open')
const formRef = ref<FormInstance>()
const driverNames = ref<string[]>([])
const initTableData = ref<any[]>([])
const driverDetail = ref()
const localInstance = ref({ driverName: '', driverInstanceName: '', autoStartEnabled: false, cycleSeconds: 3, description: '', initParam: {} as any })
const tableColumn = ref<TableColumnType[]>([
  { title: '参数名称', dataIndex: 'name', width: 200 },
  { title: '参数编号', dataIndex: 'identifier', width: 200 },
  { title: '选项', dataIndex: 'data' },
])

watch(() => props.instance, async (newInstance) => {
  if (newInstance != null) {
    localInstance.value = cloneDeep(newInstance.driverInstance)
    await onChangeDriverName(localInstance.value.driverName)
  }
}, { immediate: true })

watch(open, async (newOpen) => {
  if (newOpen === true) {
    await getDrivers()
  }
}, { immediate: true })

const driverNameOptions = computed(() => {
  return driverNames.value.map(item => ({ value: item, label: item }))
})

function filterOption(input: string, option: any) {
  return option.value.toLowerCase().includes(input.toLowerCase())
}

async function getDrivers() {
  driverNames.value = await getDriverListApi()
}

async function onChangeDriverName(driverName: string) {
  driverDetail.value = await getDriverDetailApi(driverName)
  getInitParamTableData()
}

function getInitParamTableData() {
  const initParamModel = driverDetail.value.initParamModel
  if (initParamModel == null) {
    initTableData.value = []
    return
  }
  const tableData: any[] = []
  initParamModel.forEach((param: any) => {
    tableData.push({
      identifier: param.identifier,
      name: param.name,
      required: param.required,
      type: param.dataType.type,
      specs: param.dataType.specs,
      data: localInstance.value.initParam[param.identifier] ?? InitDataOfDataTypeMap.get(param.dataType.type),
    })
  })

  initTableData.value = tableData
}

function getDataOptions(specs: any) {
  const options: any[] = []
  map(specs, (value, key) => {
    options.push({ label: value, value: key })
  })

  return options
}

async function onSubmit() {
  formRef.value?.validate().then(async () => {
    const res = await updateDriverInstanceApi(getSubmitParam())
    if (isBoolean(res)) {
      message.success('修改成功')
      open.value = false
      emit('confirm')
      resetForm()
    }
  })
}

function resetForm() {
  formRef.value?.resetFields()
}

function getSubmitParam() {
  const initParam = localInstance.value.initParam
  const nextDriverInstance = cloneDeep(localInstance.value)
  nextDriverInstance.initParam = JSON.stringify(initParam)

  return omit(nextDriverInstance, ['runtimeLog'])
}
</script>

<template>
  <a-modal v-model:open="open" :centered="true" width="50%" title="修改" @ok="onSubmit">
    <a-form ref="formRef" :model="localInstance" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item has-feedback label="实例名称" name="driverInstanceName" required>
        <a-input v-model:value="localInstance.driverInstanceName" />
      </a-form-item>
      <a-form-item has-feedback label="驱动名称" name="driverName" required>
        <a-select v-model:value="localInstance.driverName" show-search :options="driverNameOptions" :filter-option="filterOption" @change="onChangeDriverName" />
      </a-form-item>
      <a-form-item label="自动启动" name="autoStartEnabled">
        <a-switch v-model:checked="localInstance.autoStartEnabled" />
      </a-form-item>
      <a-form-item label="采集周期" name="cycleSeconds">
        <a-input-number v-model:value="localInstance.cycleSeconds" :min="3">
          <template #addonAfter>
            秒
          </template>
        </a-input-number>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 4, span: 20 }">
        <a-table
          :data-source="initTableData" :columns="tableColumn" bordered
          :pagination="false" :scroll="{ scrollToFirstRowOnChange: true, y: 200 }"
        >
          <template #title>
            初始化参数
          </template>

          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'data'">
              <a-form-item :rules="[{ required: record.required, message: `${record.name}为必填项` }]" style="margin-bottom: 0;" :name="['initParam', record.identifier]" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                <template v-if="record.type === 'INT' || record.type === 'DECIMAL'">
                  <a-input-number v-model:value="localInstance.initParam[record.identifier]" style="width: 100%" :min="record.specs.min ??	-Infinity" :max="record.specs.max ?? Infinity" />
                </template>
                <template v-else-if="record.type === 'TEXT'">
                  <a-input v-model:value="localInstance.initParam[record.identifier]" />
                </template>
                <template v-else-if="record.type === 'ENUM' || record.type === 'BOOL'">
                  <a-select v-model:value="localInstance.initParam[record.identifier]" :options="getDataOptions(record.specs)" />
                </template>
                <template v-else-if="record.type === 'DATE'">
                  <a-date-picker v-model:value="localInstance.initParam[record.identifier]" show-time format="YYYY-MM-DD HH:mm:ss" value-format="x" />
                </template>
                <template v-else-if="record.type === 'DAY'">
                  <a-date-picker v-model:value="localInstance.initParam[record.identifier]" format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
                </template>
              </a-form-item>
            </template>
            <template v-else>
              {{ text }}
            </template>
          </template>
        </a-table>
      </a-form-item>
      <a-form-item has-feedback label="备注" name="description">
        <a-input v-model:value="localInstance.description" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
