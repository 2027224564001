<script setup lang="ts">
import { PlusOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons-vue'
import { Button, Dropdown, Menu, Space, Tag, Tooltip } from 'ant-design-vue'
import Add from './add.vue'
import Update from './update.vue'
import { deleteDriverInstanceApi, getDriverInstancesApi, startDriverInstanceApi, stopDriverInstanceApi } from '@/api/driver'
import { DriverRuntimeStateMap, DriverRuntimeStateTagColorMap } from '@/constant/driver'
import { cache } from '@/utils/tools'

const pageType = defineModel('pageType')
const instance = defineModel('instance')
const addModalOpen = ref(false)
const updateModalOpen = ref(false)
const searchWords = ref()

const modal = useModal()
const choosedInstance = ref()
const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '实例名称', dataIndex: ['driverInstance', 'driverInstanceName'] },
    { title: '采集周期（秒）', dataIndex: ['driverInstance', 'cycleSeconds'] },
    {
      title: '自动启动',
      dataIndex: ['driverInstance', 'autoStartEnabled'],
      customRender({ record }) {
        return h(Tag, { color: record.driverInstance.autoStartEnabled ? 'green' : 'error' }, () => record.driverInstance.autoStartEnabled ? '启用' : '禁用')
      },
    },
    {
      title: '运行模式',
      dataIndex: ['driverInstance', 'standalone'],
      customRender({ record }) {
        return record.driverInstance.standalone ? '独立' : '内嵌'
      },
    },
    {
      title: '运行状态',
      dataIndex: ['runtime', 'state'],
      customRender({ record }) {
        const state = record.runtime.state
        return h(Tag, { color: DriverRuntimeStateTagColorMap.get(state), icon: state === 'AUTO_RESTARTING' ? h(SyncOutlined, { spin: true }) : false }, () => DriverRuntimeStateMap.get(state))
      },
    },
    {
      title: '是否要重启',
      dataIndex: ['runtime', 'restartRequired'],
      customRender({ record }) {
        const need = record.runtime.restartRequired
        return h(Tag, { color: need ? 'error' : 'default' }, () => need ? '需要' : '不需要')
      },
    },
    {
      title: '备注',
      dataIndex: ['driverInstance', 'description'],
      ellipsis: true,
      customRender({ record }) {
        return h(Tooltip, { title: record.driverInstance.description }, () => h('div', { class: 'ellipsis' }, record.driverInstance.description))
      },
    },
    {
      title: '操作',
      width: 190,
      customRender({ record }) {
        const state = record.runtime.state
        const needStart = ['STOPPED', 'NEVER_STARTED'].includes(state)
        return h(Space, { direction: 'horizontal', wrap: false }, () => [
          h(Button, { type: 'link', size: 'small', danger: !needStart, onClick() { toggleInstance(record.driverInstance.id, needStart) } }, () => needStart ? '启动' : '停止'),
          h(Button, { type: 'link', size: 'small', onClick: () => openDetailPage(record) }, () => '详情'),
          h(
            Dropdown,
            { trigger: 'click', overlay: h(Menu, { items: [
              { key: 1, label: '编辑', onClick() {
                onEdit(record)
              } },
              { key: 2, label: '删除', style: { color: 'red' }, async onClick() {
                await onDeleteInstance(record.driverInstance.id)
              } },
            ],
            }) },
            () => h(Button, { type: 'link', size: 'small' }, () => '更多'),
          ),
        ])
      },
    },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

function onEdit(record: any) {
  choosedInstance.value = record
  updateModalOpen.value = true
}

onMounted(onSearchInstance)
async function onSearchInstance() {
  state.config.loading = true
  state.data = await getDriverInstancesApi()

  state.config.loading = false
}

async function toggleInstance(instanceId: number, needStart: boolean) {
  state.config.loading = true
  if (needStart) {
    await startDriverInstanceApi(instanceId)
  }
  else {
    await stopDriverInstanceApi(instanceId)
  }

  await onSearchInstance()
}

function openDetailPage(record: any) {
  pageType.value = 'detail'
  instance.value = record
}

async function onDeleteInstance(instanceId: number) {
  modal.confirm({ title: '确定要删除？', centered: true, async onOk() {
    await deleteDriverInstanceApi(instanceId)
    await onSearchInstance()
  } })
}

async function onUpdateConfirm() {
  await onSearchInstance()
}

function onAddInstance() {
  addModalOpen.value = true
}

async function onSearch() {
  await onSearchInstance()

  state.data = cache(state.data).filter(item => item.driverInstance.driverInstanceName.includes(searchWords.value))
}
</script>

<template>
  <content-header title="驱动实例" />
  <flex-content>
    <table-layout>
      <template #headerLeft>
        <a-space size="middle">
          <a-button type="primary" @click="onAddInstance">
            <template #icon>
              <PlusOutlined />
            </template>
            新增
          </a-button>
          <a-tooltip title="刷新">
            <ReloadOutlined :style="{ fontSize: '16px' }" @click="onSearchInstance" />
          </a-tooltip>
        </a-space>
      </template>
      <template #headerRight>
        <a-space>
          <a-input v-model:value="searchWords" placeholder="请输入实例名称" allow-clear />
          <a-button type="primary" @click="onSearch">
            搜索
          </a-button>
        </a-space>
      </template>
      <template #content>
        <base-table v-bind="state" />
      </template>
    </table-layout>
  </flex-content>

  <Add v-model:open="addModalOpen" @confirm="onUpdateConfirm" />
  <Update v-model:open="updateModalOpen" :instance="choosedInstance" @confirm="onUpdateConfirm" />
</template>
